// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

$('.donationAmountOther strong').append(" £");


// Meet the team page - Making posts hover only - no click
$('.listedPost.PostCategory_unclickable').each(function () {
  $(this).find('a').removeAttr("href");
  $(this).find('a').prop("onclick", null).css("cursor", "auto");
  $(this).prop("onclick", null).css("cursor", "auto");
});


// The function that does the image resize magic. Hoisted from similar function in framework.
function staffImageResize(img, width, height) {
  var updatedSrc = $(img)
    .attr('src')
    .replace(/w=([0-9]*)&h=([0-9]*)/, 'w=' + width + '&h=' + height) // No smart-cropping
    .replace(
      /width=([0-9]*)&height=([0-9]*)/,
      'width=' + width + '&height=' + height
    ); // Smart cropping
  $(img).attr('src', updatedSrc);
}

// Change listed post images on the 'Staff' category to square
// Every 500ms
setInterval(function () {
  $('.listedPost.PostCategory_staff .banner').each(function() {
    staffImageResize(this, 700, 900);
  });
}, 250);